.app-title {
  color: white;
}

.container-text-bar {
  text-align: center;
}

.app-description {
  color: white;
}
