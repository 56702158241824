.btn-whatsapp {
  background-color: green !important;
  color: white !important;
}

.card-action {
  display: flex;
  align-items: center;
  justify-content: center;
}
