.flex-container {
  display: flex;
  justify-content: center;
}

.flex-container > .div {
  background-color: #f1f1f1;
  width: 100px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}
